import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { baseUrl, environment } from 'src/environments/environment';
import { User } from './_models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: BehaviorSubject<string> = new BehaviorSubject(null);
  user: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.token.next(localStorage.getItem("token"));
    environment.token = localStorage.getItem("token");
    let u=localStorage.getItem("token");
    if(u)
    this.user.next(u);
   }

   login(data): Observable<any> {
    let response = this.http.post(`${baseUrl}login`, data);
    return response.pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  editUser(data): Observable<any> {
    let response = this.http.post(`${baseUrl}editprofile`, data, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteUser(data): Observable<any>{
    let response = this.http.post(`${baseUrl}deleteUser`, {user_id:data}, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateToken(newToken, user: User) {
    environment.token = newToken;
    localStorage.setItem('token', environment.token);
    localStorage.setItem('user', JSON.stringify(user));
    // console.log('this is the localstorage thingy with user: ',user);
  }

  registration(data): Observable<any>{
    let response = this.http.post(`${baseUrl}register`, data, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  resetPassword(data): Observable<any>{
    let response = this.http.post(`${baseUrl}forgot`, data, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getUsers(): Observable<any>{
    let response = this.http.get(`${baseUrl}users`, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response;
  }

   handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      // console.log('this is e.e.m', error.error.message)
    } else {
      // server-side error
      errorMessage = error.error.error;
      // console.log('this is e.s', error.message)
      // console.log('this is e.m', error.message)
    }
    window.alert(errorMessage);
    console.log('this is error message', errorMessage)
    return throwError(errorMessage);
  }
  

  getEmail(userInput){
    localStorage.setItem('email', userInput)
  }
}
