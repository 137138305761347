<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img src="assets/images/circle-user.png" alt="user" class="rounded-circle" width="50">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <!-- <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                </div> -->
                <div class="m-l-10">
                    <h5 class="m-b-0">{{email}}</h5>
                    <br>
                    <p class=" m-b-0">{{name}}</p>
                </div>
            </div>
           <!-- <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a> -->
         <!--     <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div> -->
            <a class="dropdown-item" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <!-- <div class="dropdown-divider"></div> -->
            <!-- <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div> -->
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>