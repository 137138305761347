import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  user : any;
  email:string= "email";
  name: string= "name";

  constructor(private modalService: NgbModal, private router: Router) {

    // this.user = localStorage.getItem("user");
    // this.user = JSON.parse(this.user);

    // this.email = "email";
    // this.name = "name";

    // console.log ("user information", this.user["email"])

  }

  

   ngAfterViewInit() {
    this.user = localStorage.getItem("user");
    this.user = JSON.parse(this.user);

    this.email = this.user ["email"];
    this.name = this.user ["name"];
    // console.log ("user information", this.user["name"])
   }

  logout()
  {
    localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }
}
