import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '',
    //     title: 'Personal',
    //     icon: 'mdi mdi-dots-horizontal',
    //     class: 'nav-small-cap',
    //     extralink: true,
    //     submenu: []
    // },

    {
        path: '/apps/userlist',
        title: 'Users',
        icon: 'icon-Business-Mens',
        class: '',
        extralink: false,
        submenu: []
    },

   
    // {
    //     path: '/dashboard/dashboard1',
    //     title: 'Admin Dashboard',
    //     icon: 'icon-Car-Wheel',
    //     class: '',
    //     extralink: false,
    //     submenu: []
    // },

    // {
    //     path: '/dashboard/dashboard2',
    //     title: 'Agent Dashboard',
    //     icon: 'icon-Car-Wheel',
    //     class: '',
    //     extralink: false,
    //     submenu: []
    // },

    {
        path: '/ecom/buildings',
        title: 'Commercial',
        icon: 'icon-Building',
        class: '',
        extralink: false,
        submenu: [
            {
                path: '/ecom/offices',
                title: 'Offices',
                icon: 'icon-Office',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/ecom/floor',
                title: 'Floors',
                icon: 'icon-Administrator',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/apps/officebooking',
                title: 'Office Bookings List',
                icon: 'icon-Administrator',
                class: '',
                extralink: false,
                submenu: []
            },
        
            {
                path: '/ecom/meetingroom',
                title: 'Meeting Rooms',
                icon: 'icon-Business-Mens',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/apps/bookinglist',
                title: 'Meeting Bookings List',
                icon: 'icon-Administrator',
                class: '',
                extralink: false,
                submenu: []
            },
        ]
    },

    {
        path: '/ecom/locations',
        title: 'Residentials',
        icon: 'icon-Home',
        class: '',
        extralink: false,
        submenu: [
            {
                path: '/ecom/homes',
                title: 'Homes',
                icon: 'icon-Office',
                class: '',
                extralink: false,
                submenu: []
            },
        ]
    },

    {
        path: '/ecom/news',
        title: 'News And Blogs',
        icon: 'icon-Files',
        class: '',
        extralink: false,
        submenu: [
            {
                path: '/ecom/addnews',
                title: 'Add News Or Blogs',
                icon: 'icon-Files',
                class: '',
                extralink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/apps/registration',
        title: 'User Registration',
        icon: 'icon-User',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/sample-pages/propertyregistration',
        title: 'Property Registration',
        icon: 'icon-Files',
        class: '',
        extralink: false,
        submenu: []
    },

   
];
